/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";
import {Helmet} from "react-helmet";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import axios from "axios";
import CardsFooter from "components/Footers/CardsFooter";
import { Link } from "react-router-dom";
import Badge from "reactstrap/lib/Badge";

class ProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        console.log("PRPS", this.props)

        let productId = null;
        if (this.props.match.params.productId) {
            productId = this.props.match.params.productId
        }
        axios.get("https://vivomaxpro.com/public_content/products.json").then((response) => {
            this.setState({
                product: response.data.find((prd) => prd.id === productId)
            })
        })
    }
    render() {
        const { product } = this.state;
        return (
            <>
                {product && <Helmet>
                    <title>Vivo Max Pro Clean - {product.title}</title>
                    <link rel="canonical" href={"http://vivomaxpro.com/product/" + product.id} />
                </Helmet>}
                <DemoNavbar />
                {product && <main className="profile-page product-details" ref="main">
                    <section className="parallax header-section pdp-header section-profile-cover section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            {/* <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span /> */}
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Row className="card-profile mt--300">
                                <Col>
                                    <Button className="mb-4 back-btn" color="primary" tag={Link} to="/"><i class="fa fa-chevron-left"></i> Назад</Button>

                                    <Card className="shadow">
                                        <div className="px-4">
                                            <Row className="mt-2">
                                                <Col>

                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xl="6" lg="8" md="8" sm="12" className="text-center">
                                                    <img
                                                        alt="..."
                                                        height="auto"
                                                        width="100%"
                                                        className="pdp-image"
                                                        src={product.imageSrc}
                                                    />
                                                </Col>
                                                <Col>
                                                    <div className="text-left">
                                                        <h1>
                                                            <Badge pill color="primary">{product.brand}</Badge><br />
                                                            <span className="font-weight-light">{product.title}</span>
                                                        </h1>
                                                        <ul className={"unstyled-list pd-attributes-list"}>
                                                            <li><Badge pill>Разфасовка</Badge><Badge pill color="primary">5L</Badge></li>
                                                        </ul>
                                                        <div className="mt-5 py-3">
                                                            <Row>
                                                                <Col>
                                                                    <h5>Предназначение</h5>
                                                                    <p>
                                                                        {product.purpose}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="mt-5 mb-5 py-3 border-top">
                                                            <Row>
                                                                <Col>
                                                                    <h5>Начин на употреба</h5>
                                                                    <p>
                                                                        {product.use}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>}
                <SimpleFooter />
            </>
        );
    }
}

export default ProductDetails;
