/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import axios from "axios";
import { Link } from "react-router-dom";
import SimpleFooter from "components/Footers/SimpleFooter";

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;

        axios.get("https://vivomaxpro.com/public_content/products.json").then((response) => {
            this.setState({
                products: response.data
            })
        })
    }
    render() {
        return (
            <>
                <DemoNavbar />
                <main ref="main">
                    <div className="position-relative">
                        {/* shape Hero */}
                        <section className="parallax header-section homepage-header section section-lg section-shaped pb-250">
                            <div className="shape shape-style-1 shape-default">
                                {/* <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span /> */}
                            </div>
                            <Container className="py-lg-md d-flex">
                                <div className="col px-0">
                                    <Row>
                                        <Col lg="12" className='text-center'>
                                            <h1 className="display-3 text-white">
                                                Ние сме повече{" "}
                                                <span>от производители</span>
                                            </h1>
                                            <p className="lead text-white">
                                                Ние сме водеща компания в производството на високо качество почистващи препарати за лична и търговска употреба.
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                            {/* SVG separator */}
                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="2560 0 2560 100 0 100"
                                    />
                                </svg>
                            </div>
                        </section>
                        {/* 1st Hero Variation */}
                    </div>
                    <section className="section section-lg pt-lg-0 mt--200">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <Row className="row-grid">

                                        {this.state.products.map((product) => {
                                            return <Col  lg="4" className="mt-2">
                                                <Card className="card-lift--hover shadow border-0">
                                                    <CardBody className="py-5">
                                                        <img width="100%" height="auto" src={product.imageSrc} alt={product.title} />
                                                        <h6 className="text-primary text-uppercase">
                                                            {product.title}
                                                        </h6>
                                                        <p className="description mt-3">
                                                            {product.shortDescription}
                                                        </p>
                                                        <Button
                                                            className="mt-4"
                                                            color="primary"
                                                            tag={Link}
                                                            to={"/product/" + product.id}
                                                        >
                                                            Научете повече
                                                        </Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section section-lg bg-default">
                        <Container className="pt-lg pb-300">
                            <Row className="text-center justify-content-center">
                                <Col lg="10">
                                    <h2 className="display-3 text-white">Защо Нас?</h2>
                                    <p className="lead text-white">
                                    Ние се стремим да предоставим продукти от най-високо качество и отлични услуги, заради които нашите клиенти да се връщат при нас отново и отново. Ако сте недоволни от сегашния си прозводител или пък ако тепърва започвате да търсите такъв, Изберете Нас - Изберете Актуел.
                                    </p>
                                </Col>
                            </Row>
                
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <section className="section section-lg pt-lg-0 section-contact-us">
                        <Container>
                            <Row className="justify-content-center mt--300">
                                <Col lg="8">
                                    <Card className="bg-gradient-secondary shadow">
                                        <CardBody className="p-lg-5">
                                            <h4 className="mb-1 h-primary-border">Искате да работите с нас?</h4>
                                            <p>
                                                Моля свържете се:
                                            </p>
                                            <p className="mb-0">
                                                <i class="fa fa-phone"></i> +359 (0) 888399455<br />
                                                <i class="fa fa-envelope"></i> info@vivomaxpro.com
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <SimpleFooter />
            </>
        );
    }
}

export default Homepage;
