/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer style={{ backgroundColor: "#fff" }} className="footer">
          <Container>
            <Row className="align-items-center justify-content-md-between">
              <Col md="6" sm="12" className="text-center text-lg-left">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href={"/"}
                  >
                    Vivo Max Pro
                  </a>
                  .
                </div>
              </Col>
              <Col md="6" sm="12">
                <Nav className="nav-footer text-center text-lg-right justify-content-center justify-content-lg-end">
                  {/* {<NavItem>
                    <NavLink
                      tag={Link}
                      to={"https://facebook.com"}
                    >
                      <i className="fa fa-facebook-square" /> Facebook
                    </NavLink>
                  </NavItem>} */}
                  <Button
                    className="btn-icon-only rounded-circle ml-1"
                    color="facebook"
                    href="https://www.facebook.com/"
                    id="tooltip837440414"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-facebook-square" />
                    </span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip837440414">
                    Харесайте ни
                  </UncontrolledTooltip>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
